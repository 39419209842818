/*** Import / Initialisation des variables ***/

	/* Conf */
	@import '../global/settings';
	@import 'foundation';

/********************************************************************************/
/************************* SHARE / BLOC NOTE / MAJ  *****************************/
/********************************************************************************/

	.social_share_print .print, .social_share_print .share_btn button {background-repeat:no-repeat; background-position:center center;}
	.social_share_print .print {@include print($full_black); background-size:1.7rem auto;}
	.social_share_print .share_btn button {@include share($full_black); z-index:1; position:relative; background-size:auto 1.6rem;}
	
	.content_page .social_share_print ul {margin:0; line-height:1;}
	.content_page .social_share_print ul li {background:none; display:inline-block; line-height:0.000001rem; padding:0; vertical-align:top;}
	.content_page .social_share_print button::after {display:none;}

	.social_share_print .btns_container {margin:0;}
	.social_share_print .btns_container li.print_btn {padding-right:1rem;}
	.social_share_print .btns_container .share_btn > button, .social_share_print .btns_container > li > a {border:0.2rem solid $yellow;}
	.social_share_print .btns_container .share_btn > button, .social_share_print .btns_container > li > a,
	.social_share_print .share_container li {padding:0; width:3.4rem; height:3.4rem; display:block; transition:0.3s ease; background-color:$yellow; border-radius:50%; position:relative; box-shadow:none;}

	.social_share_print .btns_container .share_btn > button:hover, .social_share_print .btns_container .share_btn > button:focus,
	.social_share_print .btns_container > li > a:hover, .social_share_print .btns_container > li > a:focus,
	.social_share_print .btns_container .share_btn > button[aria-expanded="true"] {border-color: $full_black;}

	/*** BOUTONS DE PARTAGE ***/
		.social_share_print .share_container {width:auto; background-color:transparent; border:0; margin-right:-0.5rem;}
		.social_share_print .share_container ul li { overflow:hidden;}
		.social_share_print .share_container ul {padding:0.5rem 0 0.5rem 0; display:flex; margin:0;}
		.social_share_print .share_container li:not(:first-child) {margin-left:0.5rem;}
		.social_share_print .share_container li a {display:block; float:none; width:100%; height:100%; border:none; padding:0; background-repeat:no-repeat; background-position:center center; opacity:0.6;}
		.social_share_print .share_container li a:hover, .social_share_print .share_container li a:focus {opacity:1; box-shadow:none;}
		.social_share_print .share_container.sharing {display:block;}
		.social_share_print .share_container.sharing a::after {display:none;}
		.social_share_print .share_container .a2a_button_facebook {@include share_facebook($white); background-color:#2b4d86; background-size:auto 1.2rem;}
		.social_share_print .share_container .a2a_button_twitter {@include share_twitter($white); background-color:#057eb6; background-size:auto 1rem;}
		.social_share_print .share_container .a2a_button_linkedin {@include share_linkedin($white); background-color:#3e67c2; background-size:auto 1.2rem;}

		
/********************************************************************************/
/******************************** RESPONSIVE ************************************/
/********************************************************************************/

	@media screen and #{breakpoint(large down)} {}

	@media screen and #{breakpoint(medium down)} {}

	@media screen and #{breakpoint(small down)} {
		.head_page .social_share_print > .grid-x {@include xy-grid(horizontal, true);}
		.social_share_print .btns_container {justify-content:center;}
		.head_page .social_share_print, #fil_ariane {@include xy-cell-base(grow); @include xy-cell-size(100%);}
	}
